<template>
    <h2 class="center">本站信息</h2>
    <a-row class="center">
        <a-col :span="6">
            <a-statistic title="用户数" :value="users" style="margin-right: 50px"/>
        </a-col>
        <a-col :span="6">
            <a-statistic title="客服部工单数" :value="customer"/>
        </a-col>
        <a-col :span="6">
            <a-statistic title="设计部工单数" :value="designer"/>
        </a-col>
        <a-col :span="6">
            <a-statistic title="运营部工单数" :value="operator"/>
        </a-col>
    </a-row>
</template>

<script>
    import {computed, defineComponent, reactive, ref, onMounted} from 'vue';
    import {index} from "@/network/admin"
    import {message} from "ant-design-vue";

    export default defineComponent({
        name: "Index",
        setup() {
            const users = ref(0);
            const customer = ref(0);
            const designer = ref(0);
            const operator = ref(0);
            const getStatistic = () => {
                index().then((response) => {
                    //console.log(response);
                    if (response.status == 'done') {
                        users.value = response.users;
                        customer.value = response.customer;
                        designer.value = response.designer;
                        operator.value = response.operator;
                    } else {
                        message.error(response.msg);
                    }
                })
            }
            onMounted(()=>{
                getStatistic();
            })
            return{
                users,
                customer,
                designer,
                operator
            }
        },
    });
</script>

<style scoped>
.center{
    text-align: center;
}
</style>
